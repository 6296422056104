import React, { Component } from 'react'

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: -28.0914483,
      lng: 153.4425208
    },
    zoom: 14
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ width: '100%' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3132.3528521446033!2d-0.6870077843379954!3d38.27131269120818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd63b687991a0c21%3A0x5aea6c04a10a699!2sCafeter%C3%ADa%20Taper%C3%ADa%20Fama!5e0!3m2!1ses!2ses!4v1591306345319!5m2!1ses!2ses"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Google Maps F.A.M.A"
        ></iframe>
      </div>
    )
  }
}

export default GoogleMap

// const Marker = () => {
//   return (
//     <div style={{ color: 'red' }}>
//       <MapPin />
//     </div>
//   )
// }
